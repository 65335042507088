import Drawer from '@/components/drawers/Drawer' 

import StorageHelper from '@/helpers/localStorageHelper'
import ApiBase from '@/api/base'
import ApiTranslations from '@/api/translations'
import ApiEmails from '@/api/emails'

export default {
	name: 'ComingSoonContainer',
	components: { 
		Drawer, 
	},
	metaInfo () {
		return {
			titleTemplate: '%s | 1tn'
		}
	},
	created () {
		ApiEmails.getAll(this)
	},
	methods: {
		changeLocale (e) {
			StorageHelper.set('language', e)
			ApiBase.setToStore(null, 'setCurrentLang', e)
			ApiTranslations.getByCurrentLanguage(this)
		}
	}
}
