import ApiBase from '@/api/base'

class ApiEmails {
  static getAll (self) {
    self.http('get', `${ApiBase.baseApiUrl()}/admin-emails`)
      .then(response => {
        ApiBase.setToStore(null, 'setEmails', response.data)
      })
  }
}

export default ApiEmails
