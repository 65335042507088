<template>
<div>
	<v-dialog
		v-model="dialog"
		:width="$vuetify.breakpoint.smAndDown ? '100%' : '540'"
		class="modal"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				v-if="btnType === 'header'"
				small
				minWidth="150"
				height="40"
				depressed
				class="sign-btn sign-up px-md-0 mr-md-6"
				:ripple="false"
				v-bind="attrs"
				v-on="on"
			>
				<span
					class="vue-editor white--text font-weight-bold px-md-6"
					v-html="trans('header_btn_request_access')"
				/>
			</v-btn>
			<v-btn
				v-else-if="btnType === 'default'"
				:minWidth="$vuetify.breakpoint.smAndDown ? '152' : '170'"
				:ripple="false"
				:height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
				depressed
				:small="$vuetify.breakpoint.smAndDown"
				class="business-btn"
				v-bind="attrs"
				v-on="on"
			>
				<span
					class="vue-editor font-weight-bold"
					v-html="trans('welcome_page_business')"
				/>
			</v-btn>
		</template>
		<FormComponent />
	</v-dialog>
</div>
</template>

<script>
import FormComponent from './form-component/FormComponent'

export default {
	name: 'AcceptCoinsDialog',
	components: {
		FormComponent
	},
	props: {
		btnType: {
			type: String,
			default: 'default'
		}
	},
	data: () => ({
		dialog: false
	}),
	mounted () {
		this.$root.$on('closeModalWindow', () => {
			this.dialog = false
		})
	}
}

</script>

<style lang="scss" scoped>
.business-btn {
	border: 1px var(--v-mainColor-base) solid;
  border-radius: 30px !important;
  background: transparent !important;
  transition: all 0.4s;
	color: var(--v-mainColor-base);
  &:hover {
    background: var(--v-mainColor-base) !important;
		color: white;
  }
}
.sign-btn {
  color: white;
  border-radius: 20px;
  transition: 0.4s;
}
.sign-up {
  background: rgba($color: #8ddcda, $alpha: 0.6) !important;

  &:hover {
    background: rgba(255, 255, 255, 0.3) !important;
  }
}
</style>
