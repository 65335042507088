var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type === 'dropdown')?_c('v-menu',{attrs:{"right":"","close-on-content-click":"","offset-y":"","content-class":"products-menu"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex align-center px-0",attrs:{"ripple":false,"text":"","height":"36","dark":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"vue-editor text-body-2 black--text font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.trans('header_item_products'))}}),_c('v-icon',{staticClass:"icon ml-1",attrs:{"color":attrs['aria-expanded'] === 'true' ? 'mainColor' : 'black'}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,2857113384)},[_c('div',{staticClass:"d-flex flex-column white pa-5"},[_c('span',{staticClass:"text-body-2 mb-6 text-uppercase",domProps:{"innerHTML":_vm._s(_vm.trans('header_products_personal'))}}),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
							name: 'Wallet'
					}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{attrs:{"width":"28","height":"34"}},[_c('v-img',{attrs:{"src":require("@/assets/images/products-wallet-icon.svg")}})],1),_c('div',{staticClass:"ml-3 d-flex flex-column"},[_c('h4',{class:[
									'vue-editor item-title text-body-2 font-weight-black mb-1',
									_vm.$route.name === 'Wallet' ? 'active' : 'black--text'
								],domProps:{"innerHTML":_vm._s(_vm.trans('header_products_item_wallet'))}})])],1)]),_c('div',{staticClass:"divider d-flex"},_vm._l((30),function(dot,dotIndex){return _c('div',{key:dotIndex,staticClass:"divider-dot borderGray mt-8 mb-6"})}),0),_c('span',{staticClass:"vue-editor text-body-2 mb-6 text-uppercase",domProps:{"innerHTML":_vm._s(_vm.trans('header_products_business'))}}),_vm._l((_vm.products),function(product){return _c('router-link',{key:product.to,staticClass:"text-decoration-none product-item",attrs:{"to":{
							name: product.to
						}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{attrs:{"width":product.imageWidth,"height":product.imageHeight}},[_c('v-img',{attrs:{"src":product.src}})],1),_c('div',{staticClass:"ml-3 d-flex flex-column"},[_c('h4',{class:[
										'vue-editor item-title text-body-2 font-weight-black',
										_vm.$route.name === product.to ? 'active' : 'black--text'
									],domProps:{"innerHTML":_vm._s(product.title)}})])],1)])})],2)]):(_vm.type === 'accordion')?_c('v-expansion-panels',{staticClass:"accordion",attrs:{"accordion":""},model:{value:(_vm.index),callback:function ($$v) {_vm.index=$$v},expression:"index"}},[_c('v-expansion-panel',{attrs:{"active-class":"expanded"}},[_c('v-expansion-panel-header',{staticClass:"header text-body-2 grayText--text font-weight-bold py-0",attrs:{"expand-icon":"mdi-menu-right"}},[_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans('header_item_products'))}})]),_c('v-expansion-panel-content',{staticClass:"text-md-body-1 grayText--text pb-8 text-caption"},[_c('div',{staticClass:"d-flex flex-column white mt-4"},[_c('span',{staticClass:"vue-editor text-body-2 mb-6 text-uppercase",domProps:{"innerHTML":_vm._s(_vm.trans('header_products_personal'))}}),_c('div',{staticClass:"text-decoration-none",on:{"click":function($event){return _vm.goToPage('Wallet')}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{attrs:{"width":"28","height":"34"}},[_c('v-img',{attrs:{"src":require("@/assets/images/products-wallet-icon.svg")}})],1),_c('h4',{class:[
									'vue-editor item-title text-body-2 font-weight-black ml-3',
									_vm.$route.name === 'Wallet' ? 'active' : 'black--text'
								],domProps:{"innerHTML":_vm._s(_vm.trans('header_products_item_wallet'))}})],1)]),_c('div',{staticClass:"divider d-flex"},_vm._l((300),function(dot){return _c('div',{key:dot,staticClass:"divider-dot borderGray mt-8 mb-6"})}),0),_c('span',{staticClass:"vue-editor text-body-2 mb-6 text-uppercase",domProps:{"innerHTML":_vm._s(_vm.trans('header_products_business'))}}),_vm._l((_vm.products),function(product){return _c('div',{key:product.to,staticClass:"text-decoration-none product-item",on:{"click":function($event){return _vm.goToPage(product.to)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{attrs:{"width":product.imageWidth,"height":product.imageHeight}},[_c('v-img',{attrs:{"src":product.src,"height":product.imageHeight}})],1),_c('h4',{class:[
									'vue-editor item-title text-body-2 font-weight-black ml-3',
									_vm.$route.name === product.to ? 'active' : 'black--text'
								],domProps:{"innerHTML":_vm._s(product.title)}})],1)])})],2)])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }