<template>
	<v-navigation-drawer
		style="z-index: 100"
		class="elevation-0 pt-16"
		v-if="$vuetify.breakpoint.smAndDown"
		height="100vh"
		width="100%"
		absolute
		right
		v-model="drawer"
	>
		<div class="d-flex align-center justify-space-between buttons content">
			<v-btn
				small
				height="40"
				width="150"
				depressed
				class="sign-btn sign-in mr-6"
				:ripple="false"
				@click="goToPage('WelcomePage')"
			>
				<span
					class="vue-editor white--text font-weight-bold"
					v-html="trans('header_btn_log_in')"
				/>
			</v-btn>
			<AcceptCoinsDialog btnType="header"/>
		</div>
		<div class="content mb-10">
			<ContactSalesDialog btnType="mobile burger" />
		</div>
		<Products type="accordion"/>
		<CompanyElement type="accordion"/>
		<ul class="pl-0">
			<li
				class="menu-item pointer"
				v-for="(item, i) in menuItems"
				:key="i"
			>
				<span
					class="vue-editor d-block text-decoration-none text-body-2 grayText--text font-weight-bold"
					@click="goToPage(item.src)"
					v-html="item.name"
				/>
			</li>
			<v-divider class="top-divider" />
			<li
				class="menu-item pointer d-flex justify-space-between align-center"
				@click="showLanguageMenu"
			>
				<span class="text-decoration-none text-body-2 grayText--text font-weight-bold">
					{{ currentLanguageName }}
				</span>
				<v-icon class="icon ml-1">
					mdi-menu-down
				</v-icon>
			</li>
			<v-divider class="bottom-divider" />
		</ul>
	</v-navigation-drawer>
</template>

<script>
import Products from '@/components/main-header/Products'
import ContactSalesDialog from '@/components/dialogs/contact-sales-dialog/ContactSalesDialog'
import CompanyElement from '@/components/main-header/CompanyElement'
import AcceptCoinsDialog from '@/components/dialogs/accept-coins-dialog/AcceptCoinsDialog'

export default {
	name: 'Drawer',
	components: {
		Products,
		ContactSalesDialog,
		CompanyElement,
		AcceptCoinsDialog
	},
	data: () => ({
		drawer: false
	}),
	computed: {
		menuItems () {
			return [
				{
					name: this.trans('header_item_blog'),
					src: 'Blog'
				},
				{
					name: this.trans('header_item_become_partner'),
					src: 'BecomePartner'
				}
			]
		}
	},
	methods: {
		showLanguageMenu () {
			this.$root.$emit('showLanguageDrawer')
		},
		goToPage (src) {
			if (this.$route.name !== src) {
					this.$root.$emit('closeProducts')
				this.$router.push({
					name: src
				})
				this.$root.$emit('closeBurgerMenu')
			}
		}
	},
	mounted() {
		this.$root.$off('showDrawer')
		this.$root.$on('showDrawer', () => {
			this.drawer = !this.drawer
		})
	}
}
</script>

<style lang="scss" scoped>
.buttons {
	background: var(--v-mainColor-base);
	height: 69px;

	.sign-btn {
		color: white;
		border-radius: 20px;
		transition: 0.4s;
	}

	.sign-in {
		background: transparent !important;
		border: 1px solid rgba(255, 255, 255, 0.2);

		&:hover {
			background: rgba(255, 255, 255, 0.2) !important;
		}
	}

	.sign-up {
		background: rgba(255, 255, 255, 0.2) !important;

		&:hover {
			background: rgba(255, 255, 255, 0.3) !important;
		}
	}
}
ul {
	list-style: none;

	.menu-item {
		padding: 14px 25px;
		position: relative;

		&:hover {
			background: rgba(244, 252, 255, 0.5);

			&::before {
				position: absolute;
				content: "";
				width: 3px;
				height: 100%;
				left: 0;
				top: 0;
				border-radius: 0 3px 3px 0;
				background: var(--v-mainColor-base);
			}

			span {
				color: var(--v-black-base) !important;
			}
		}
	}
}
.top-divider {
	margin-top: 15px !important;
	margin-bottom: 5px !important;
}
.bottom-divider {
	margin-top: 5px !important;
}
.icon {
	transform: rotate(270deg) !important;
}
</style>
