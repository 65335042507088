<template>
	<div>
		<v-dialog
			v-model="dialog"
			:width="$vuetify.breakpoint.smAndDown ? '100%' : '540'"
			class="modal"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-if="btnType === 'header'"
					small
					height="40"
					minWidth="100"
					depressed
					class="header-btn main-green--background px-0"
					:ripple="false"
					v-bind="attrs"
					v-on="on"
				>
					<span
						class="vue-editor white--text font-weight-bold"
						v-html="trans('header_btn_contact_us')"
					/>
				</v-btn>
				<span
					v-else-if="btnType === 'footer'"
					v-bind="attrs"
					v-on="on"
					class="vue-editor grayText--text mb-3 text-body-1 text-decoration-none"
					v-html="trans('footer_btn_contact_us')"
				/>
				<v-btn
					v-else-if="btnType === 'default'"
					:ripple="false"
					:height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
					depressed
					:small="$vuetify.breakpoint.smAndDown"
					class="black-btn px-md-9 px-11"
					v-bind="attrs"
					v-on="on"
				>
					<span
						class="vue-editor font-weight-bold white--text"
						v-html="trans('white_label_page_btn_contact')"
					/>
				</v-btn>
				<v-btn
					v-else-if="btnType === 'mobile burger'"
					small
					height="40"
					width="100%"
					depressed
					class="mobile-burger-btn mt-4"
					:ripple="false"
					v-bind="attrs"
					v-on="on"
				>
					<span
						class="vue-editor mainColor--text text-body-2"
						v-html="trans('white_label_page_btn_contact')"
					/>
				</v-btn>
				<v-btn
					v-else-if="btnType === 'secure'"
					:ripple="false"
					:height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
					depressed
					:small="$vuetify.breakpoint.smAndDown"
					class="black-btn mr-md-10"
					v-bind="attrs"
					v-on="on"
				>
					<span
						class="vue-editor font-weight-bold px-md-7 px-8 white--text"
						v-html="trans('custody_solution_last_btn')"
					/>
				</v-btn>
			</template>
			<FormComponent />
		</v-dialog>
	</div>
</template>

<script>
import FormComponent from "./form-component/FormComponent";

export default {
	name: "ContactSalesDialog",
	components: {
		FormComponent,
	},
	props: {
		btnType: {
			type: String,
			default: "default",
		},
	},
	data: () => ({
		dialog: false,
	}),
	mounted() {
		this.$root.$on("closeModalWindow", () => {
			this.dialog = false;
		});
	},
};
</script>

<style lang="scss" scoped>
.header-btn {
	color: white;
	border-radius: 20px;
	transition: 0.4s;
}
.mobile-burger-btn {
	border-radius: 20px;
	transition: 0.4s;
	background-color: white !important;
	border: 1px solid var(--v-mainColor-base);
}
.black-btn {
	border-radius: 30px !important;
	background-color: white !important;
	background: var(--v-black-base) !important;
	transition: all 0.4s;

	&:hover {
		background: rgba($color: #000000, $alpha: 0.9) !important;
	}
}
</style>
