var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type === 'dropdown')?_c('v-menu',{attrs:{"right":"","close-on-content-click":"","offset-y":"","content-class":"link-menu","minWidth":"340"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex align-center px-0",attrs:{"ripple":false,"text":"","height":"36","dark":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"vue-editor text-body-2 black--text font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.trans('header_item_company') )}}),_c('v-icon',{staticClass:"icon ml-1",attrs:{"color":attrs['aria-expanded'] === 'true' ? 'mainColor' : 'black'}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,501718615)},[_c('div',{staticClass:"d-flex flex-column white pa-5"},_vm._l((_vm.links),function(link){return _c('router-link',{key:link.to,staticClass:"text-decoration-none link-item",attrs:{"to":{
							name: link.to
						}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{attrs:{"width":"26","height":"26"}},[_c('v-img',{attrs:{"src":link.src}})],1),_c('div',{staticClass:"ml-3 d-flex flex-column"},[_c('h4',{class:[
										'vue-editor item-title text-body-2 font-weight-black',
										_vm.$route.name === link.to ? 'active' : 'black--text'
									],domProps:{"innerHTML":_vm._s(link.title)}})])],1)])}),1)]):(_vm.type === 'accordion')?_c('v-expansion-panels',{staticClass:"accordion",attrs:{"accordion":""},model:{value:(_vm.index),callback:function ($$v) {_vm.index=$$v},expression:"index"}},[_c('v-expansion-panel',{attrs:{"active-class":"expanded"}},[_c('v-expansion-panel-header',{staticClass:"header text-body-2 grayText--text font-weight-bold py-0",attrs:{"expand-icon":"mdi-menu-right"}},[_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans('header_item_company'))}})]),_c('v-expansion-panel-content',{staticClass:"text-md-body-1 grayText--text pb-8 text-caption"},[_c('div',{staticClass:"d-flex flex-column white mt-4"},_vm._l((_vm.links),function(link){return _c('div',{key:link.to,staticClass:"text-decoration-none link-item",on:{"click":function($event){return _vm.goToPage(link.to)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{attrs:{"width":"28","height":"28"}},[_c('v-img',{attrs:{"src":link.src}})],1),_c('h4',{class:[
									'vue-editor item-title text-body-2 font-weight-black ml-3',
									_vm.$route.name === link.to ? 'active' : 'black--text'
								],domProps:{"innerHTML":_vm._s(link.title)}})],1)])}),0)])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }