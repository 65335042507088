<template>
<div>
	<v-menu
		v-if="type === 'dropdown'"
		right
		close-on-content-click
		offset-y
		content-class="products-menu"
	>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					class="d-flex align-center px-0"
					:ripple="false"
					text
					height="36"
					dark
					v-bind="attrs"
					v-on="on"
				>
					<span
						class="vue-editor text-body-2 black--text font-weight-bold"
						v-html="trans('header_item_products')"
					/>
					<v-icon class="icon ml-1" :color="attrs['aria-expanded'] === 'true' ? 'mainColor' : 'black'">
						mdi-menu-down
					</v-icon>
				</v-btn>
			</template>
			<div class="d-flex flex-column white pa-5">
				<span
					class="text-body-2 mb-6 text-uppercase"
					v-html="trans('header_products_personal')"
				/>
				<router-link
					class="text-decoration-none"
					:to="{
							name: 'Wallet'
					}"
				>
					<div class="d-flex align-center">
						<v-sheet width="28" height="34">
							<v-img src="@/assets/images/products-wallet-icon.svg" />
						</v-sheet>
						<div class="ml-3 d-flex flex-column">
							<h4 :class="[
									'vue-editor item-title text-body-2 font-weight-black mb-1',
									$route.name === 'Wallet' ? 'active' : 'black--text'
								]"
								v-html="trans('header_products_item_wallet')"
							/>
						</div>
					</div>
				</router-link>
					<div class="divider d-flex">
						<div
							v-for="(dot, dotIndex) in 30"
							:key="dotIndex"
							class="divider-dot borderGray mt-8 mb-6"
						/>
					</div>
					<span
						class="vue-editor text-body-2 mb-6 text-uppercase"
						v-html="trans('header_products_business')"
					/>
					<router-link
						v-for="product in products"
						:key="product.to"
						:to="{
							name: product.to
						}"
						class="text-decoration-none product-item"
					>
						<div class="d-flex align-center">
							<v-sheet
								:width="product.imageWidth"
								:height="product.imageHeight"
							>
									<v-img :src="product.src" />
							</v-sheet>
							<div class="ml-3 d-flex flex-column">
								<h4 :class="[
										'vue-editor item-title text-body-2 font-weight-black',
										$route.name === product.to ? 'active' : 'black--text'
									]"
									v-html="product.title"
								/>
							</div>
						</div>
					</router-link>
			</div>
	</v-menu>
	<v-expansion-panels
		v-else-if="type === 'accordion'"
		accordion
		v-model="index"
		class="accordion"
	>
		<v-expansion-panel active-class="expanded">
			<v-expansion-panel-header
				expand-icon="mdi-menu-right"
				class="header text-body-2 grayText--text font-weight-bold py-0"
			>
				<span v-html="trans('header_item_products')" class="vue-editor" />
			</v-expansion-panel-header>
			<v-expansion-panel-content class="text-md-body-1 grayText--text pb-8 text-caption">
				<div class="d-flex flex-column white mt-4">
					<span
						class="vue-editor text-body-2 mb-6 text-uppercase"
						v-html="trans('header_products_personal')"
					/>
					<div
						class="text-decoration-none"
						@click="goToPage('Wallet')"
					>
						<div class="d-flex align-center">
							<v-sheet width="28" height="34">
								<v-img src="@/assets/images/products-wallet-icon.svg" />
							</v-sheet>
							<h4 :class="[
									'vue-editor item-title text-body-2 font-weight-black ml-3',
									$route.name === 'Wallet' ? 'active' : 'black--text'
								]"
								v-html="trans('header_products_item_wallet')"
							/>
						</div>
					</div>
					<div class="divider d-flex">
						<div
							v-for="dot in 300"
							:key="dot"
							class="divider-dot borderGray mt-8 mb-6"
						/>
					</div>
					<span
						class="vue-editor text-body-2 mb-6 text-uppercase"
						v-html="trans('header_products_business')"
					/>
					<div
						v-for="product in products"
						:key="product.to"
						@click="goToPage(product.to)"
						class="text-decoration-none product-item"
					>
						<div class="d-flex align-center">
							<v-sheet :width="product.imageWidth" :height="product.imageHeight">
								<v-img :src="product.src" :height="product.imageHeight" />
							</v-sheet>
							<h4 :class="[
									'vue-editor item-title text-body-2 font-weight-black ml-3',
									$route.name === product.to ? 'active' : 'black--text'
								]"
								v-html="product.title"
							/>
						</div>
					</div>
				</div>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</div>
</template>

<script>
export default {
	name: 'Products',
	data: () => ({
		index: 1
	}),
	props: {
		type: {
			type: String,
			default: 'dropdown'
		}
	},
	computed: {
		products () {
			return [
				{
					title: this.trans('header_products_item_crypto_payment_gateway'),
					to: 'Home',
					src: require('@/assets/images/products-payment-icon.svg'),
					desc: this.trans('header_products_item_crypto_payment_gateway_desc'),
					imageWidth: 27,
					imageHeight: 20
				},
				{
					title: this.trans('header_products_item_white_label'),
					to: 'WhiteLabel',
					src: require('@/assets/images/products-wl-icon.svg'),
					desc: this.trans('header_products_item_white_label_desc'),
					imageWidth: 28,
					imageHeight: 28
				},
				{
					title: this.trans('header_products_item_custody'),
					to: 'CustodySolution',
					src: require('@/assets/images/products-custody-icon.svg'),
					desc: this.trans('header_products_item_custody_desc'),
					imageWidth: 28,
					imageHeight: 28
			},
				{
					title: this.trans('header_products_item_otc_platform'),
					to: 'PremiumExchange',
					src: require('@/assets/images/products-otc-platform-icon.svg'),
					desc: this.trans('header_products_item_otc_platform_desc'),
					imageWidth: 26,
					imageHeight: 37
				}
			]
		}
	},
	methods: {
		goToPage (name) {
			if (this.$route.name !== name) {
				this.index = 1
				this.$router.push({
					name: name
				})
				this.$root.$emit('closeBurgerMenu')
			}
		}
	},
	mounted() {
		this.$root.$off('closeProducts')
		this.$root.$on('closeProducts', () => {
			this.index = 1
		})
	}
}
</script>

<style lang="scss" scoped>
.products-menu {
	box-shadow: -2px 20px 50px #2D34361A;
	border-radius: 10px;
}
.icon {
	width: 9px;
}
.item-title {
	transition: 0.2s;
	&:hover {
		color: var(--v-mainColor-base) !important;
	}
}
.divider {
	overflow: hidden;
}
.divider-dot {
	height: 1px;
	width: 5px;
	margin-right: 5px;
	flex-shrink: 0;
}
.product-item {
	margin-bottom:40px;
	&:nth-of-type(3) {
		margin-bottom: 36px;
	}
	&:nth-of-type(4) {
		margin-bottom: 33px;
	}
	&:last-of-type {
		margin-bottom: 0px;
	}
}
.active {
	color: var(--v-mainColor-base) !important;
}
.v-expansion-panel::before {
	box-shadow: none !important;
}
.header {
	transition: 0.2s;
	position: relative;
	&:hover {
		background: rgba(244, 252, 255, 0.5);
		&::after {
			position: absolute;
			content: "";
			width: 3px;
			height: 100%;
			left: 0;
			top: 0;
			border-radius: 0 3px 3px 0;
			background: var(--v-mainColor-base);
		}
	}
}
.expanded::v-deep() {
	.header {
		.v-expansion-panel-header__icon .v-icon {
			color: var(--v-mainColor-base) !important;
			transform: rotate(0deg) !important;
		}
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.product-item {
		margin-bottom: 24px;
		&:nth-of-type(3),
		&:nth-of-type(4) {
			margin-bottom: 24px;
		}
	}
}
</style>
