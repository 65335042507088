import { render, staticRenderFns } from "./ComingSoonContainer.vue?vue&type=template&id=4e8a5fe2&scoped=true&"
import script from "./coming-soon-container.js?vue&type=script&lang=js&"
export * from "./coming-soon-container.js?vue&type=script&lang=js&"
import style0 from "./ComingSoonContainer.vue?vue&type=style&index=0&id=4e8a5fe2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e8a5fe2",
  null
  
)

export default component.exports