<template>
<div>
	<v-menu
		v-if="type === 'dropdown'"
		right
		close-on-content-click
		offset-y
		content-class="link-menu"
		minWidth="340"
	>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					class="d-flex align-center px-0"
					:ripple="false"
					text
					height="36"
					dark
					v-bind="attrs"
					v-on="on"
				>
					<span
						class="vue-editor text-body-2 black--text font-weight-bold"
						v-html="trans('header_item_company') "
					/>
					<v-icon class="icon ml-1" :color="attrs['aria-expanded'] === 'true' ? 'mainColor' : 'black'">
						mdi-menu-down
					</v-icon>
				</v-btn>
			</template>
			<div class="d-flex flex-column white pa-5">
					<router-link
						v-for="link in links"
						:key="link.to"
						:to="{
							name: link.to
						}"
						class="text-decoration-none link-item"
					>
						<div class="d-flex align-center">
							<v-sheet
								width="26"
								height="26"
							>
									<v-img :src="link.src" />
							</v-sheet>
							<div class="ml-3 d-flex flex-column">
								<h4 :class="[
										'vue-editor item-title text-body-2 font-weight-black',
										$route.name === link.to ? 'active' : 'black--text'
									]"
									v-html="link.title"
								/>
							</div>
						</div>
					</router-link>
			</div>
	</v-menu>
	<v-expansion-panels
		v-else-if="type === 'accordion'"
		accordion
		v-model="index"
		class="accordion"
	>
		<v-expansion-panel active-class="expanded">
			<v-expansion-panel-header
				expand-icon="mdi-menu-right"
				class="header text-body-2 grayText--text font-weight-bold py-0"
			>
				<span v-html="trans('header_item_company')" class="vue-editor" />
			</v-expansion-panel-header>
			<v-expansion-panel-content class="text-md-body-1 grayText--text pb-8 text-caption">
				<div class="d-flex flex-column white mt-4">
					<div
						v-for="link in links"
						:key="link.to"
						@click="goToPage(link.to)"
						class="text-decoration-none link-item"
					>
						<div class="d-flex align-center">
							<v-sheet width="28" height="28">
								<v-img :src="link.src" />
							</v-sheet>
							<h4 :class="[
									'vue-editor item-title text-body-2 font-weight-black ml-3',
									$route.name === link.to ? 'active' : 'black--text'
								]"
								v-html="link.title"
							/>
						</div>
					</div>
				</div>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</div>
</template>

<script>
export default {
	name: 'CompanyElement',
	data: () => ({
		index: 1
	}),
	props: {
		type: {
			type: String,
			default: 'dropdown'
		}
	},
	computed: {
		links () {
			return [
				{
					title: this.trans('header_company_item_about'),
					to: 'AboutUs',
					src: require('@/assets/images/header-about-icon.svg'),
					desc: this.trans('header_company_item_about_desc')
				},
				{
					title: this.trans('header_company_item_careers'),
					to: 'Careers',
					src: require('@/assets/images/header-career-icon.svg'),
					desc: this.trans('header_company_item_careers_desc')
				},
				{
					title: this.trans('header_company_item_brand'),
					to: 'BrandGuide',
					src: require('@/assets/images/header-brand-icon.svg'),
					desc: this.trans('header_company_item_brand_desc')
				}
			]
		}
	},
	methods: {
		goToPage (name) {
			if (this.$route.name !== name) {
				this.index = 1
				this.$router.push({
					name: name
				})
				this.$root.$emit('closeBurgerMenu')
			}
		}
	},
	mounted() {
		this.$root.$off('closeProducts')
		this.$root.$on('closeProducts', () => {
			this.index = 1
		})
	}
}
</script>

<style lang="scss" scoped>
.links-menu {
	box-shadow: -2px 20px 50px #2D34361A;
	border-radius: 10px;
	min-width: 320px;
}
.icon {
	width: 9px;
}
.item-title {
	transition: 0.2s;
	&:hover {
		color: var(--v-mainColor-base) !important;
	}
}
.link-item {
	margin-bottom:40px;
	&:nth-of-type(3) {
		margin-bottom: 36px;
	}
	&:nth-of-type(4) {
		margin-bottom: 33px;
	}
	&:last-of-type {
		margin-bottom: 0px;
	}
}
.active {
	color: var(--v-mainColor-base) !important;
}
.v-expansion-panel::before {
	box-shadow: none !important;
}
.header {
	transition: 0.2s;
	position: relative;
	&:hover {
		background: rgba(244, 252, 255, 0.5);
		&::after {
			position: absolute;
			content: "";
			width: 3px;
			height: 100%;
			left: 0;
			top: 0;
			border-radius: 0 3px 3px 0;
			background: var(--v-mainColor-base);
		}
	}
}
.expanded::v-deep() {
	.header {
		.v-expansion-panel-header__icon .v-icon {
			color: var(--v-mainColor-base) !important;
			transform: rotate(0deg) !important;
		}
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.link-item {
		margin-bottom: 24px;
		&:nth-of-type(3),
		&:nth-of-type(4) {
			margin-bottom: 24px;
		}
	}
}
</style>
